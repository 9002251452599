<template lang="pug">
page-structure-element(
  :element="element"
  :defaultImage="defaultImage"
  :interactions="interactions"
  @clicked="selectElement(element)"
  @mouseenter="enterElement(element)"
  @mouseleave="leaveElement(element)"
) {{ $t(type(element)) }}
</template>

<script>
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import PageStructureElement from '../../PageStructureElement.vue';

  export default {
    components: { PageStructureElement },
    mixins: [pageStructureMixin],
    props: {
      element: {
        type: Object,
        required: true,
      },
      interactions: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        defaultImage: require('@/assets/editor/svg/page-structure-elements/Divider.svg'),
      };
    },
  };
</script>
