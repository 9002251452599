<template lang="pug">
div
  li.om-structure-v2-list-item.om-structure-v2-element(
    v-for="element in getElementsOfColumn(column)"
    :class="'om-structure-v2-' + type(element)"
  )
    component(:is="element.type" :element="element" :interactions="interactions")
</template>

<script>
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import Elements from './elements/index';

  export default {
    components: {
      ...Elements,
    },
    mixins: [pageStructureMixin],
    props: {
      column: {
        type: Object,
        required: true,
      },
      interactions: {
        type: Object,
        required: true,
      },
    },
  };
</script>
