import OmButton from './OmButton.vue';
import OmCheckbox from './OmCheckbox.vue';
import OmCountdown from './OmCountdown.vue';
import OmCoupon from './OmCoupon.vue';
import OmCustomHTML from './OmCustomHTML.vue';
import OmDivider from './OmDivider.vue';
import OmDropdown from './OmDropdown.vue';
import OmFeedback from './OmFeedback.vue';
import OmFloatingImage from './OmFloatingImage.vue';
import OmImage from './OmImage.vue';
import OmInput from './OmInput.vue';
import OmLuckyWheel from './OmLuckyWheel.vue';
import OmPickAPresent from './OmPickAPresent.vue';
import OmProduct from './OmProduct.vue';
import OmRadio from './OmRadio.vue';
import OmScratchCard from './OmScratchCard.vue';
import OmSocial from './OmSocial.vue';
import OmSpacer from './OmSpacer.vue';
import OmSurvey from './OmSurvey.vue';
import OmText from './OmText.vue';
import OmTextarea from './OmTextarea.vue';
import OmVideo from './OmVideo.vue';

export default {
  OmButton,
  OmCheckbox,
  OmCountdown,
  OmCoupon,
  OmCustomHTML,
  OmDivider,
  OmDropdown,
  OmFeedback,
  OmFloatingImage,
  OmImage,
  OmInput,
  OmLuckyWheel,
  OmPickAPresent,
  OmProduct,
  OmRadio,
  OmScratchCard,
  OmSocial,
  OmSpacer,
  OmSurvey,
  OmText,
  OmTextarea,
  OmVideo,
};
