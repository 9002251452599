<template lang="pug">
.om-structure-v2-list-item-box(
  @click.stop="$emit('clicked')"
  @mouseenter="hoverActive = true; $emit('mouseenter')"
  @mouseleave="hoverActive = false; $emit('mouseleave')"
)
  span(
    :id="elementId"
    :class="getClasses"
    :data-track-property="elementTrackProperty"
    @mouseleave="hideActionsTooltip"
  )
    template(v-if="src && !isSvg")
      img.om-image.mx-2(:src="src" :style="style")
      slot
    template(v-else-if="src && isSvg")
      .wrapper.mx-2.svg
        .colorful.d-flex.align-items-center
          inline-svg(:src="src")
      slot
    template(v-else)
      .wrapper.mx-2(:style="backgroundImageDefaultStyle")
        .colorful(v-if="isPoweredBy" :style="{ background: 'white' }")
          img(:src="require('@/assets/editor/svg/page-structure-elements/OptiMonk.svg')")
        .colorful(v-else :style="style")
      slot
    page-structure-visibility(:element="element")
    .actions-button(
      v-if="isActionButtonShowable"
      :class="{ selected: isSelectedOnCanvas }"
      @click.stop="showActionsTooltip"
    )
    .tooltip(ref="actionsTooltip")
      .tooltip-item(v-if="isCopyable" @click.stop="copy(elementId); hideActionsTooltip()") {{ $t('copy') }}
      .tooltip-item(v-if="isRemovable" @click.stop="remove(elementId); hideActionsTooltip()") {{ $t('remove') }}
</template>

<script>
  import readOnlyElements from '@/editor/config/readOnlyElements';
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import PageStructureVisibility from './PageStructureVisibility.vue';

  const BACKGROUND_COLOR = 'background';
  const BACKGROUND_IMAGE = 'background-image';
  const BACKGROUND_POSITION = 'background-position';
  const BACKGROUND_SIZE = 'background-size';

  export default {
    components: {
      PageStructureVisibility,
    },
    mixins: [pageStructureMixin],
    props: {
      element: {
        type: Object,
        required: false,
      },
      isImage: { type: Boolean, default: false },
      defaultImage: { required: false },
      interactions: {
        type: Object,
      },
      isPoweredBy: { type: Boolean, default: false },
    },
    data() {
      return {
        src: null,
        isSvg: false,
        backgroundImageDefaultStyle: {},
        style: {},
        hoverActive: false,
        isSelectedOnCanvas: false,
        isMouseEnterOnCanvas: false,
        isHoveredOnCanvas: false,
        hasHoverInteractionOnCanvas: false,
      };
    },
    computed: {
      elementTrackProperty() {
        let tag = null;
        if (this.element?.type) {
          tag = this.element?.type;
        }
        if (!tag && this.element?.uid) {
          tag = this.element?.uid;
        }
        return `component:${tag}|setting:click|change:layers.${tag}`;
      },
      elementId() {
        return this.element?.uid ?? null;
      },
      isStructuralElement() {
        return ['OmRow', 'OmCol', 'OmPage'].includes(this.element?.type);
      },
      isWebsiteOverlay() {
        return this.element.uid === 'website-overlay';
      },
      isActionButtonShowable() {
        if (
          this.element?.uid === 'close-button' ||
          ['OmPage', 'OmCol'].includes(this.element?.type)
        )
          return false;

        return (
          (this.isCopyable || this.isRemovable) && (this.hoverActive || this.isSelectedOnCanvas)
        );
      },
      isRemovable() {
        return (
          this.element?.type === 'OmRow' ||
          (this.element && !this.isWebsiteOverlay && !readOnlyElements.includes(this.element.type))
        );
      },
      isCopyable() {
        return (
          this.element &&
          !this.isStructuralElement &&
          !this.isWebsiteOverlay &&
          !readOnlyElements.includes(this.element.type) &&
          !['OmFeedback', 'OmSurvey'].includes(this.element.type)
        );
      },
      getClasses() {
        return {
          selected: this.isSelectedOnCanvas,
          hover: (this.isHoveredOnCanvas || this.hoverActive) && !this.isSelectedOnCanvas,
        };
      },
    },
    watch: {
      defaultImage: {
        handler() {
          this.getDefaultImage();
        },
        deep: true,
      },
      interactions: {
        handler(interactions) {
          if (!interactions) {
            return;
          }
          this.handleSelectInteraction();
          this.handleHoverInteraction();
        },
        deep: true,
      },
    },
    created() {
      this.getDefaultImage();
    },
    methods: {
      setDefaultImageStyle() {
        this.backgroundImageDefaultStyle = {
          [BACKGROUND_IMAGE]: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC')`,
          [BACKGROUND_POSITION]: 'inherit',
          [BACKGROUND_SIZE]: '70%',
        };
      },
      getDefaultImage() {
        if (this.isImage) {
          this.src = this.defaultImage.src;
          this.style = {
            border: '1px solid rgba(80, 87, 99, 0.2)',
            'border-radius': '4px',
          };
          return;
        }
        this.setDefaultImageStyle();
        if (this.defaultImage instanceof Object) {
          if (this.defaultImage.background === 'gradient') {
            this.style = {
              [BACKGROUND_IMAGE]: this.defaultImage[BACKGROUND_IMAGE],
            };
            this.src = null;
          }
          if (this.defaultImage.background === 'image') {
            this.src = this.defaultImage.src;
            this.isSvg = this.defaultImage?.svg;
          }
          if (this.defaultImage.background === 'solid-fill') {
            this.style = {
              [BACKGROUND_COLOR]: this.defaultImage.color,
            };
            this.src = null;
          }
          if (this.defaultImage.background === 'transparent') {
            this.style = null;
            this.src = null;
          }
        } else if (typeof this.defaultImage === 'string') {
          this.src = this.defaultImage;
          this.style = {};
        }
      },
      showActionsTooltip(event) {
        this.$nextTick(() => {
          const tooltipEl = this.$refs.actionsTooltip;
          const targetEl = event.target;
          const { top } = targetEl.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.pointerEvents = 'auto';
          tooltipEl.style.right = `29px`;
          tooltipEl.style.top = `${top - 16}px`;
        });
      },
      hideActionsTooltip() {
        this.$refs.actionsTooltip.style.opacity = 0;
        this.$refs.actionsTooltip.style.left = null;
        this.$refs.actionsTooltip.style.bottom = null;
        this.$refs.actionsTooltip.style.pointerEvents = null;
      },
      handleSelectInteraction() {
        const selectedElementId = this.interactions?.selectedElement?.uid;
        if (!selectedElementId) {
          this.isSelectedOnCanvas = false;
          return;
        }
        this.isSelectedOnCanvas = selectedElementId === this.elementId;
      },
      handleHoverInteraction() {
        const elements = this.getHoverInteractionArray();
        this.hasHoverInteractionOnCanvas = [...elements].length > 0;

        if (!elements.includes(this.elementId)) {
          this.isMouseEnterOnCanvas = false;
          this.isHoveredOnCanvas = false;
          return;
        }
        this.isMouseEnterOnCanvas = true;

        if (elements.slice(-1)[0] === this.elementId) {
          this.isHoveredOnCanvas = true;
          return;
        }
        this.isHoveredOnCanvas = false;
      },
      getHoverInteractionArray() {
        const elements = [];
        for (const uid of this.interactions?.hoveredElementArray) {
          elements.push(uid);
        }
        return elements;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .actions-button
    cursor: pointer
    min-width: 32px
    min-height: 32px
    background: url('../../../../assets/editor/svg/actions-button-dots-vertical.svg') no-repeat center
    right: 0
    top: 0
    border-radius: 4px
    transition-duration: .5
    margin: 4px 4px 4px auto
    &:hover
      background-color: rgba(185, 190, 198, 0.2)
    &.selected
      background: url('../../../../assets/editor/svg/actions-button-dots-vertical-selected.svg') no-repeat center
      &:hover
        background-color: rgba(237, 90, 41, 0.1) !important
  .tooltip
    position: fixed
    background: #fff
    border-radius: 4px
    border: 1px solid #E3E5E8
    box-sizing: border-box
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.04)
    font-size: 14px
    line-height: 1.5
    color: #505763
    z-index: 999999
    opacity: 0
    pointer-events: none
    &:after
      content: ''
      position: absolute
      left: 0
      top: 100%
      height: 1px
      width: 100%
    &-item
      padding: 8px 12px
      cursor: pointer
      &:hover
        background-color: #F1F2F4
</style>
